import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import H1 from "../../components/h1"
import Button from "../../components/button"
import ContentPage from "../../layouts/contentPage"
import ContentPageWrapper from "../../layouts/contentPageWrapper"
import ContentPageBg from "../../layouts/contentPageBg"
import Logotype from "../../images/logotype.svg";
import AnimatedBubble from "../../components/animatedBubble"

const About = () => (
    <Layout lang={'EN'}>
        <Seo title="About brand" lang={'EN'}/>
        <ContentPage>
            <ContentPageWrapper>
                <Logotype className="logotype"/>
                <H1 className="subtitle uppercase"></H1>
                <div className="row">
                    <div className="col-6">
                        <h2 className="header-content">
                            <strong>The Oxygenetic brand was designed with environment, modern lifestyle and fast pace of life in mind and to take your skincare routine to the next level. </strong>
                        </h2>
                        <p>The brand is all about the revolutionary synergetic effect, the symbiosis of an unparallel selection of actives for optimal skincare tailored to the demands of modern life.  Minerals boost  metabolic processes to oxygenate body cells. Synbiotics form a protective barrier for balanced skin microbiome. Pro youth activators Vitamin C and A supply essential actives. Oxygenetic ranges for maximised absoption of nutrients. </p>
                        <p>Skincare is never one-size fits all. Oxygenetic ranges are to be explored to find a unique combination to suit one's needs the best  possible way. </p>
                        <AnimatedBubble />
                    </div>
                    <div className="col-6">
                        <p><strong className="lime">Oxygenetic Energyze</strong> Fancy and functional for happy skin. Created to make the revitalising & reviving skincare the best it can be. Packed with actives to lock in moisture and rehydrate thirsty skin instantly. For women to enjoy deeply hydrated, invigorated and radiant skin. Hydration is a pathway to achieving luminous complexion. Glowy skin within  grasp with our Oxygenetic Energize range. Oxygenetic Energize range is a supercharge lightweight energize formula that lives up its name. </p>
                        <p><strong className="magenta">Oxygenetic Relift</strong> To embrace active ageing. In order to meet the specific needs of mature skin, Oxygenetic  has developed a comprehensive skin care program that not only prevents the formation of wrinkles, but also effectively eliminates the existing ones. Thanks to new technologies and active ingredients, Oxygenetic has managed to create a number of unique products that allow not only to slow down the ageing process, but actually reverse the signs of time! After a certain age, the skin begins to lose its firmness and elasticity, changing its structure and as a result forming wrinkles and deep lines. With age, the structure of the skin tends to deteriorate. The thickness of the skin and the distribution of subcutaneous fat begins to change, which leads to the skin sagging and the appearance of wrinkles. This is a natural process.  Now to be reveresed.</p>
                        <p><strong className="blue">Oxygenetic Acid Peel</strong> Acid packed, science backed skincare to help bolster skin microbiome, promoting healthier looking and happier skin. Created to protect skin against environmental stressors and keeps skin feeling superbly awakened.  </p>

                    </div>
                </div>
                <Button as={Link} to="/en/" primary="true" className="back">Homepage</Button>
            </ContentPageWrapper>
            <ContentPageBg>
                <StaticImage
                    src="../images/media/about/about-bg.png"
                    loading="eager"
                    width={552}
                    quality={95}
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="oxygenetic"
                />
            </ContentPageBg>
        </ContentPage>
    </Layout>
)

export default About
